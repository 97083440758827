import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useFeatureEnabled } from '../../../../services/features';
import { ADMITAD } from './api/admitad';
import { ADS_BY_MONEY } from './api/adsbymoney';
import { AFFILAE } from './api/affilae';
import { AFFILIATE_WP } from './api/affiliatewp';
import { AFFILIATLY } from './api/affiliatly';
import { AFFISE } from './api/affise';
import { AMAZON_FEED } from './api/amazon';
import { AMAZON_V2 } from './api/amazonV2';
import { AVANTLINK } from './api/avantlink';
import { AWIN } from './api/awin';
import { BELBOON } from './api/belboon';
import { BLUEHOST } from './api/bluehost';
import { CAKE } from './api/cake';
import { CANNAFFILIATE } from './api/cannaffiliate';
import { CBD_AFFS } from './api/cbdaffs';
import { CIVITATIS } from './api/civitatis';
import { CJ } from './api/cj';
import { CLICKBANK } from './api/clickbank';
import { COMMISSION_FACTORY } from './api/commissionfactory';
import { DAISYCON } from './api/daisycon';
import { DIGIDIP } from './api/digidip';
import { EBAY } from './api/ebay';
import { EFFILIATION } from './api/effiliation';
import { ENGINE } from './api/engine';
import { ENGINE_V2 } from './api/engineV2';
import { EVERFLOW } from './api/everflow';
import { EXPRESS_VPN } from './api/expressvpn';
import { FINANCEADS } from './api/financeads';
import { FIRSTPROMOTER } from './api/firstpromoter';
import { FLEX_OFFERS } from './api/flexoffers';
import { FRESHREACH } from './api/freshreach';
import { GET_YOUR_GUIDE_API } from './api/getyourguide';
import { GO_AFF_PRO } from './api/goaffpro';
import { GO_BANKING_RATES } from './api/gobankingrates';
import { HOWL } from './api/howl';
import { IDEVAFFILIATE } from './api/idevaffiliate';
import { IMPACT } from './api/impact';
import { JVZOO } from './api/jvzoo';
import { KUTOKU } from './api/kutoku';
import { LEADDYNO } from './api/leaddyno';
import { LEVANTA } from './api/levanta';
import { LINK_CONNECTOR } from './api/linkconnector';
import { LTK } from './api/ltk';
import { MAX_BOUNTY } from './api/maxbounty';
import { MVF } from './api/mvf';
import { ODYSSEY } from './api/odyssey';
import { OPTIMISE } from './api/optimise';
import { PARTNERIZE } from './api/partnerize';
import { PARTNERSTACK } from './api/partnerstack';
import { PEPPERJAM } from './api/pepperjam';
import { POST_AFFILIATE_PRO } from './api/postaffiliatepro';
import { RAKUTEN } from './api/rakuten';
import { RAKUTEN_V2 } from './api/rakutenV2';
import { RED_VENTURES } from './api/redventures';
import { REFERSION } from './api/refersion';
import { REVOFFERS } from './api/revoffers';
import { SHAREASALE } from './api/shareasale';
import { SKIMLINKS } from './api/skimlinks';
import { SOVRN } from './api/sovrn';
import { STACKED_BRANDS } from './api/stackedbrands';
import { STAY22 } from './api/stay22';
import { TRADEDOUBLER } from './api/tradedoubler';
import { TRADETRACKER } from './api/tradetracker';
import { TRAVEL_PAYOUTS } from './api/travelpayouts';
import { TUNE } from './api/tune';
import { VOYAGIN } from './api/voyagin';
import { WEBGAINS } from './api/webgains';
import { WEBGAINS_V2 } from './api/webgainsV2';
import { AGODA } from './csv/agoda';
import { AMAZON } from './csv/amazon';
import { BOL } from './csv/bol';
import { BOOKING } from './csv/booking';
import { GET_YOUR_GUIDE } from './csv/gyg';
import { HOTELS_COMBINED } from './csv/hotelscombined';
import { KLOOK } from './csv/klook';
import { RENTALCARS } from './csv/rentalcars';
import { TAPFILIATE } from './csv/tapfiliate';
import { UPPROMOTE } from './csv/uppromote';
import { VIATOR } from './csv/viator';
import {
  IAffiliateProgramReportHandler,
  IApiReportHandler,
  IFileReportHandler,
  ReportHandler
} from './types';
import { AFFILIMATE_PRISM } from './api/affilimateprism';

export const XLSX_REPORT_HANDLERS: IFileReportHandler<string[]>[] = [BOL];

export const CSV_REPORT_HANDLERS: IFileReportHandler<string>[] = [
  AMAZON,
  AGODA,
  BOOKING,
  KLOOK,
  GET_YOUR_GUIDE,
  HOTELS_COMBINED,
  RENTALCARS,
  TAPFILIATE,
  VIATOR,
  UPPROMOTE
];

export const API_REPORT_HANDLERS: IApiReportHandler[] = [
  ADMITAD,
  ADS_BY_MONEY,
  AFFILAE,
  AFFILIATLY,
  AFFILIATE_WP,
  AFFILIMATE_PRISM,
  AFFISE,
  AMAZON_FEED,
  AMAZON_V2,
  AVANTLINK,
  AWIN,
  BELBOON,
  BLUEHOST,
  CAKE,
  CANNAFFILIATE,
  CBD_AFFS,
  CIVITATIS,
  CJ,
  CLICKBANK,
  COMMISSION_FACTORY,
  DAISYCON,
  DIGIDIP,
  EBAY,
  EFFILIATION,
  ENGINE,
  ENGINE_V2,
  EVERFLOW,
  EXPRESS_VPN,
  FINANCEADS,
  FIRSTPROMOTER,
  FLEX_OFFERS,
  FRESHREACH,
  GET_YOUR_GUIDE_API,
  GO_AFF_PRO,
  GO_BANKING_RATES,
  HOWL,
  IDEVAFFILIATE,
  IMPACT,
  JVZOO,
  KUTOKU,
  LEVANTA,
  LEADDYNO,
  LINK_CONNECTOR,
  LTK,
  MAX_BOUNTY,
  MVF,
  ODYSSEY,
  OPTIMISE,
  PARTNERIZE,
  PARTNERSTACK,
  PEPPERJAM,
  POST_AFFILIATE_PRO,
  RAKUTEN,
  RAKUTEN_V2,
  RED_VENTURES,
  REFERSION,
  REVOFFERS,
  SHAREASALE,
  SKIMLINKS,
  SOVRN,
  STACKED_BRANDS,
  TRADEDOUBLER,
  TRADETRACKER,
  TRAVEL_PAYOUTS,
  TUNE,
  VOYAGIN,
  WEBGAINS,
  WEBGAINS_V2,
  STAY22
];

const AFFILIATE_PROGRAM_HANDLER: IAffiliateProgramReportHandler = {
  type: 'AFFILIATE_PROGRAM',
  partnerKey: 'affilimate'
};

export const REPORT_HANDLERS: ReportHandler[] = sortBy(
  [...CSV_REPORT_HANDLERS, ...XLSX_REPORT_HANDLERS, ...API_REPORT_HANDLERS],
  (r) => r.partnerKey
);

export const useReportHandlers = () => {
  return REPORT_HANDLERS.filter((r) => r.status !== 'deprecated');
};

export const useApiReportHandlers = () => {
  return API_REPORT_HANDLERS.filter((r) => r.status !== 'deprecated');
};

export const useAllHandlers = () => {
  const usesAffiliateProgram = useFeatureEnabled('AFFILIATE_PROGRAM_V1');
  const reportHandlers = useReportHandlers();
  return useMemo(() => {
    return usesAffiliateProgram
      ? [AFFILIATE_PROGRAM_HANDLER, ...reportHandlers]
      : reportHandlers;
  }, [reportHandlers, usesAffiliateProgram]);
};
