import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { FlexContainer } from '../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { css } from '../../../../emotion';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { CampaignStatusIcon } from '../CampaignStatusIcon';
import React from 'react';
import { useRoutes } from '../../../../routes';
import { Helmet } from 'react-helmet';
import { CampaignMetricSelector } from './CampaignMetricSelector';
import {
  campaignStatusTitle,
  ReadyManualCampaign
} from '../../service/manual-campaign';
import { CampaignTimeframe } from './CampaignTimeframe';
import { useCurrentUser } from '../../../../services/currentUser';
import { CampaignMetric } from '../../../../domainTypes/campaigns';

const AVAILABLE_METRICS: CampaignMetric[] = ['gmv_sum_net', 'c', 'p'];

export const CampaignReportToolbar: React.FC<{
  metric: CampaignMetric;
  setMetric: (metric: CampaignMetric) => void;
  campaign: ReadyManualCampaign;
}> = ({ campaign, metric, setMetric }) => {
  const { ROUTES } = useRoutes();
  const { tz } = useCurrentUser();
  return (
    <>
      <Helmet>
        <title>{campaign.name} | Affilimate</title>
      </Helmet>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <div>
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                position: 'relative',
                top: -3,
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                gap: 16
              }}
              component="span"
            >
              {campaign.name}
            </Typography>
            <FlexContainer alignItems="center" spacing={1}>
              {campaign.advertisers.map((advertiser) => advertiser.name)}
              <Link
                to={ROUTES.campaigns.manual.details.url(campaign.id, 'report')}
              >
                <FlexContainer
                  spacing={0.5}
                  alignItems="center"
                  className={css((t) => ({
                    color: t.palette.grey[600]
                  }))}
                >
                  <Edit
                    size={16}
                    className={css(() => ({
                      position: 'relative',
                      top: -1
                    }))}
                  />
                  <Typography
                    variant="caption"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Edit campaign
                  </Typography>
                </FlexContainer>
              </Link>
            </FlexContainer>
          </div>
        </PageToolbarSection>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <FlexContainer spacing={1.5}>
            <FlexContainer alignItems="center">
              <CampaignStatusIcon status={campaign.status} />
              <Typography variant="body1">
                {campaignStatusTitle(campaign.status)}
              </Typography>
            </FlexContainer>
            <CampaignMetricSelector
              metric={metric}
              setMetric={setMetric}
              metrics={AVAILABLE_METRICS}
            />
            <CampaignTimeframe
              start={campaign.timeframe.start}
              end={campaign.timeframe.end}
              timezone={tz}
            />
          </FlexContainer>
        </PageToolbarSection>
      </PageToolbar>
    </>
  );
};
