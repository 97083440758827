import React from 'react';
import { Tooltip, TooltipPayload, TooltipProps } from 'recharts';
import { timeLabelFormatter } from '../../../../components/analytics_v2/Chart/service/formatters';
import {
  TooltipHeading,
  TooltipPaper,
  TooltipRow
} from '../../../../components/Charts/CustomTooltip';
import Typography from '@material-ui/core/Typography';
import {
  formatMetric,
  Metric,
  metricTitle
} from '../../../../services/analyticsV2/metrics';
import { WithShape } from '../../../../components/Charts/Util';
import { COLOR_UNKNOWN } from '../../../../services/color';
import { AnalyticsInterval } from '../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { Target } from 'react-feather';
import { css } from '../../../../emotion';
import { isNil } from 'lodash';
import { ChartContext } from '../../../../components/analytics_v2/Chart/chart-context';
import { CampaignMetric } from '../../../../domainTypes/campaigns';

const All: React.FC<{
  payload: TooltipPayload;
  metric: Metric;
  currency: CurrencyCode;
}> = ({ payload: { color, value }, metric, currency }) => {
  if (value === 0) {
    return null;
  }
  return (
    <TooltipRow>
      <WithShape color={color ?? COLOR_UNKNOWN}>All</WithShape>
      <span>{formatMetric(value as number, metric, currency)}</span>
    </TooltipRow>
  );
};

const GoalLabel = () => (
  <div
    className={css((t) => ({
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      gridColumnGap: t.spacing(1),
      alignItems: 'center'
    }))}
  >
    <Target size={12} />
    <strong>Goal</strong>
  </div>
);

type CampaignTooltipProps = TooltipProps & {
  payload: NonNullable<TooltipProps['payload']>;
  metric: Metric;
  interval: AnalyticsInterval;
  context: {
    startsInCurrentYear: boolean;
    currency: CurrencyCode;
  };
  hasSplit?: boolean;
  goal: number | null;
};

const CampaignTooltip: React.FC<CampaignTooltipProps> = ({
  label,
  payload,
  metric,
  context,
  interval,
  hasSplit = false,
  goal
}) => {
  const formatLabel = timeLabelFormatter(
    context.startsInCurrentYear,
    interval.unit
  );
  const labelStr = formatLabel(label as string);

  return (
    <TooltipPaper>
      <TooltipHeading>
        <Typography variant="body1">{labelStr}</Typography>
      </TooltipHeading>
      <strong>{metricTitle(metric)}</strong>
      {!hasSplit ? (
        <All payload={payload[0]} metric={metric} currency={context.currency} />
      ) : (
        payload
          .filter((p) => p.value > 0)
          .map(({ color, name, value }) => {
            return (
              <TooltipRow>
                <WithShape color={color ?? COLOR_UNKNOWN}>{name}</WithShape>
                <span>
                  {formatMetric(value as number, metric, context.currency)}
                </span>
              </TooltipRow>
            );
          })
      )}
      {isNil(goal) ? null : (
        <TooltipRow>
          <GoalLabel />
          <span>{formatMetric(goal, metric, context.currency)}</span>
        </TooltipRow>
      )}
    </TooltipPaper>
  );
};

export const tooltip = (
  metric: CampaignMetric,
  analyticsInterval: AnalyticsInterval,
  context: ChartContext,
  goal: number | null,
  hasSplit: boolean
) => (
  <Tooltip
    key="timeseries-tooltip"
    cursor={false}
    wrapperStyle={{ zIndex: 1000 }}
    separator=": "
    content={({ payload, label }: TooltipProps) => {
      if (!payload?.length) {
        return null;
      }
      return (
        <CampaignTooltip
          label={label}
          payload={payload}
          metric={metric}
          interval={analyticsInterval}
          context={context}
          hasSplit={hasSplit}
          goal={goal}
        />
      );
    }}
  />
);
