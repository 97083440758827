import React, { ComponentProps, useMemo, useState } from 'react';
import { Metric } from '../../../services/analyticsV2/metrics';
import { styled } from '../../../emotion';
import {
  createNameColumn,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../components/analytics_v2/Table/TopTable/TopTable';
import { DeviceIconWithLabel } from '../../../components/DeviceIcon';
import { Device } from '../../../domainTypes/tracking';
import { COLORS } from '../../../domainTypes/colors';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import {
  ProductCellWithoutLinkLazy,
  ProductLinkCellLazyMinimal
} from '../../Links/pages/Overview/components/ProductLinkCell';
import {
  dbColumn,
  utmParameterTitle,
  UtmParameterType
} from '../../Content/pages/Traffic/Utm/service/utm';
import { Truncated } from '../../../components/Truncated';
import { SelectUtm } from '../../../components/SelectUtm';
import { FullTableFooter, fullTableQuery } from './FullTable';
import { ExportQueryButton } from '../../../components/ExportQuery';
import { FlexContainer } from '../../../layout/Flex';
import {
  campaignQueryBase,
  ReadyManualCampaign
} from '../service/manual-campaign';
import { ReadySegmentCampaign } from '../service/segment-campaign';
import { createCampaignBaseQuery } from '../pages/segment/SegmentCampaignReport';
import { CampaignMetric } from '../../../domainTypes/campaigns';

type ExportButtonProps = {
  title: string;
  header?: React.ReactNode;
} & Pick<
  ComponentProps<typeof TopTable>,
  | 'range'
  | 'compareRange'
  | 'groupField'
  | 'filters'
  | 'metric'
  | 'columnTransformers'
>;

const ExportButton: React.FC<ExportButtonProps> = ({
  title,
  range,
  groupField,
  filters,
  metric,
  columnTransformers = [],
  compareRange
}) => (
  <ExportQueryButton
    title={`Export ${title}`}
    reportType="campaigns"
    query={fullTableQuery(
      range,
      groupField,
      manualMetrics,
      filters,
      { field: metric, direction: 'DESC' },
      columnTransformers,
      compareRange
    )}
  />
);

type CampaignTableProps = {
  className?: string;
  title: string;
  includeFooter?: boolean;
  header?: React.ReactNode;
  description?: string;
  expandedMetrics?: Metric[];
} & ComponentProps<typeof TopTable>;

const CampaignTable: React.FC<CampaignTableProps> = ({
  className,
  title,
  includeFooter,
  header,
  description,
  expandedMetrics = manualMetrics,
  ...tableProps
}) => {
  return (
    <TopPaper className={className}>
      <TopHeader title={title} description={description}>
        <FlexContainer spacing={0.5} alignItems="top">
          {header}
          <ExportButton title={title} {...tableProps} />
        </FlexContainer>
      </TopHeader>
      <TopTable {...tableProps} />
      {includeFooter ? (
        <FullTableFooter
          title={title}
          {...tableProps}
          metrics={expandedMetrics}
        />
      ) : null}
    </TopPaper>
  );
};

interface TableProps {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  className?: string;
  metric: Metric;
  availableMetrics: Metric[];
}

const baseMetrics: (baseMetric: Metric) => Metric[] = (m: Metric) => {
  return m === 'c' ? ['c', 'gmv_sum_net'] : ['c', m];
};

const manualMetrics: Metric[] = [
  'c',
  'p',
  'v',
  'gmv_sum_net',
  'commission_sum_net',
  'aov_net',
  'order_count_net'
];

const segmentMetrics: Metric[] = ['c', 'commission_sum_net'];

const DeviceTable: React.FC<TableProps> = ({
  metric,
  filters,
  range,
  className,
  availableMetrics
}) => {
  const deviceColumn = useMemo(
    () =>
      createNameColumn(
        (d) => <DeviceIconWithLabel device={d.group.device as Device} />,
        'Device',
        COLORS.lime.lime1,
        metric
      ),
    [metric]
  );

  return (
    <CampaignTable
      className={className}
      title="Devices"
      description="Performance by device type"
      groupField={'device'}
      availableMetrics={availableMetrics}
      metric={metric}
      filters={filters}
      range={range}
      nameColumn={deviceColumn}
    />
  );
};

const ProductsTable: React.FC<TableProps> = ({
  metric,
  className,
  filters,
  range,
  availableMetrics
}) => {
  const productColumn = useMemo(
    () =>
      createNameColumn(
        (d) => <ProductCellWithoutLinkLazy productId={d.group.p_catalog_uid} />,
        'Product',
        COLORS.red.red1,
        metric
      ),
    [metric]
  );
  return (
    <CampaignTable
      className={className}
      title="Products clicked"
      description="Engagement of the products included in your campaign"
      includeFooter
      nameColumn={productColumn}
      groupField={'p_catalog_uid'}
      availableMetrics={availableMetrics}
      metric={metric}
      filters={filters}
      range={range}
    />
  );
};

const LinkTable: React.FC<TableProps> = ({
  metric,
  className,
  filters,
  range,
  availableMetrics
}) => {
  const linkColumn = useMemo(
    () =>
      createNameColumn(
        (d, o) => (
          <ProductLinkCellLazyMinimal
            spaceId={o.spaceId}
            productId={d.group.link_id}
          />
        ),
        'Link name or deeplink',
        COLORS.red.red1,
        metric
      ),
    [metric]
  );

  return (
    <CampaignTable
      title="Links and products"
      description="Performance by product linked"
      className={className}
      includeFooter
      nameColumn={linkColumn}
      groupField={'link_id'}
      availableMetrics={availableMetrics}
      metric={metric}
      filters={filters}
      range={range}
    />
  );
};

const ContentTable: React.FC<TableProps> = ({
  metric,
  filters,
  range,
  className,
  availableMetrics
}) => {
  const contentColumn = useMemo(
    () =>
      createNameColumn(
        (d) => <Truncated title={d.group.page_url} />,
        'Page URL',
        COLORS.blue.blue1,
        metric
      ),
    [metric]
  );
  return (
    <CampaignTable
      title="Campaign content"
      description="Performance of links within your campaign content"
      className={className}
      includeFooter
      nameColumn={contentColumn}
      groupField={'page_url'}
      availableMetrics={availableMetrics}
      metric={metric}
      filters={filters}
      range={range}
    />
  );
};

const UtmTable: React.FC<TableProps> = ({
  className,
  metric,
  filters,
  range,
  availableMetrics
}) => {
  const [utmParam, setUtmParam] = useState<UtmParameterType>('campaign');
  const title = useMemo(() => utmParameterTitle(utmParam), [utmParam]);
  const utmColumn = useMemo(
    () =>
      createNameColumn(
        (d) => <Truncated title={d.group[dbColumn(utmParam)]} />,
        title,
        COLORS.blue.blue1,
        metric
      ),
    [metric, title, utmParam]
  );

  return (
    <CampaignTable
      className={className}
      title={`Top ${title}`}
      includeFooter
      header={<SelectUtm value={utmParam} onChange={setUtmParam} />}
      description={`Performance for traffic via UTM parameters`}
      nameColumn={utmColumn}
      groupField={dbColumn(utmParam)}
      availableMetrics={availableMetrics}
      metric={metric}
      filters={filters}
      range={range}
    />
  );
};

const TrackingLabelTable: React.FC<TableProps> = ({
  className,
  filters,
  range
}) => {
  const trackingLabelColumn = useMemo(
    () =>
      createNameColumn(
        (d) => <Truncated title={d.group.tracking_label} />,
        'Tracking label',
        COLORS.purple.purple1,
        'gmv_sum_net'
      ),
    []
  );

  return (
    <CampaignTable
      className={className}
      title="SubID"
      description="Performance by SubID"
      includeFooter
      nameColumn={trackingLabelColumn}
      groupField={'tracking_label'}
      availableMetrics={['gmv_sum_net', 'commission_sum_net']}
      metric={'gmv_sum_net'}
      filters={filters}
      range={range}
      expandedMetrics={[
        'gmv_sum_net',
        'commission_sum_net',
        'aov_net',
        'order_count_net'
      ]}
    />
  );
};

const COLUMN_COUNT = 2;

const Grid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${COLUMN_COUNT}, minmax(0, 1fr))`,
  gap: p.theme.spacing(2)
}));

const SpanRows = styled('div')`
  grid-column: span ${COLUMN_COUNT};
`;

export const SegmentCampaignTables: React.FC<{
  campaign: ReadySegmentCampaign;
  metric: CampaignMetric;
}> = ({ campaign, metric }) => {
  const { filters, range } = useMemo(() => createCampaignBaseQuery(campaign), [
    campaign
  ]);

  return (
    <>
      <Grid>
        <ContentTable
          metric={metric}
          filters={filters}
          range={range}
          availableMetrics={segmentMetrics}
        />
        <ProductsTable
          metric={metric}
          filters={filters}
          range={range}
          availableMetrics={segmentMetrics}
        />
      </Grid>
      <Grid>
        <UtmTable
          metric={metric}
          filters={filters}
          range={range}
          availableMetrics={segmentMetrics}
        />
        <DeviceTable
          metric={metric}
          filters={filters}
          range={range}
          availableMetrics={segmentMetrics}
        />
      </Grid>
    </>
  );
};

export const ManualCampaignTables: React.FC<{
  campaign: ReadyManualCampaign;
  metric: CampaignMetric;
}> = ({ campaign, metric }) => {
  const { range, filters } = useMemo(() => campaignQueryBase(campaign), [
    campaign
  ]);

  const shortMetricsList = useMemo(() => baseMetrics(metric), [metric]);

  return (
    <>
      <Grid>
        <SpanRows>
          <ContentTable
            metric={metric}
            filters={filters}
            range={range}
            availableMetrics={manualMetrics}
          />
        </SpanRows>
      </Grid>
      <Grid>
        <LinkTable
          filters={filters}
          range={range}
          metric={metric}
          availableMetrics={shortMetricsList}
        />
        <TrackingLabelTable
          filters={filters}
          range={range}
          metric={metric}
          availableMetrics={shortMetricsList}
        />
      </Grid>
      <Grid>
        <UtmTable
          filters={filters}
          range={range}
          metric={metric}
          availableMetrics={shortMetricsList}
        />
        <DeviceTable
          filters={filters}
          range={range}
          metric={metric}
          availableMetrics={shortMetricsList}
        />
      </Grid>
    </>
  );
};
