import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Edit } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Legend, WithShape } from '../../../../../components/Charts/Util';
import { useIntervalSelectorState } from '../../../../../components/IntervalSelector';
import { NoPermissions } from '../../../../../components/NoPermissions';
import {
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsQuery
} from '../../../../../domainTypes/analytics_v2';
import { COLORS } from '../../../../../domainTypes/colors';
import { css, styled } from '../../../../../emotion';
import { useAnalyticsInterval } from '../../../../../hooks/timeframe';
import { FlexContainer } from '../../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../../layout/PageToolbar';
import { useRoutes } from '../../../../../routes';
import { metricTitle } from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import {
  LoadingValue,
  useMappedLoadingValue
} from '../../../../../services/db';
import { CampaignSummaryLayout } from '../../../components/CampaignSummaryLayout';
import { SegmentCampaignTables } from '../../../components/CampaignTables';
import {
  CampaignBudgetCard,
  CampaignClicksCard,
  CampaignCPCCard,
  CampaignEstimatedCPCCard,
  CampaignImpressionsCard,
  CampaignPageviewsCard,
  CampaignTotalCardsContainer
} from '../../../components/CampaignTotalCards';
import {
  CampaignMetricSelector,
  useCampaignMetricSelectorState
} from '../../../components/report/CampaignMetricSelector';
import { CampaignTimeframe } from '../../../components/report/CampaignTimeframe';
import {
  EarningsGoalProgress,
  TrafficGoalProgress
} from '../../../components/RunningCampaignProgress';
import { SegmentCampaignPage } from '../../../components/SegmentCampaignPage';
import { CampaignTimeseries } from '../../../components/timeseries/CampaignTimeseries';
import { useSegmentCampaign } from '../../../service';
import { aggregateRows, SummedSeries } from '../../../service/aggregate';
import {
  CampaignStatusIcon,
  campaignStatusTitle,
  ReadySegmentCampaign
} from '../../../service/segment-campaign';
import { CampaignTotals, getCampaignTotals } from '../../../service/totals';
import { PauseBanner, PauseButton } from './Pause';
import {
  CampaignMetric,
  getCampaignGoalForMetric
} from '../../../../../domainTypes/campaigns';

export const createCampaignBaseQuery = (
  campaign: ReadySegmentCampaign
): {
  range: AnalyticsQuery['range'];
  filters: AnalyticsFilter[];
} => ({
  range: {
    start: campaign.timeframe.start.toISOString(),
    end: campaign.timeframe.end.toISOString()
  },
  filters: [
    {
      field: 'prism_rerouted_campaign_id',
      condition: 'in',
      values: [campaign.id]
    }
  ]
});

export const useSegmentCampaignTotals = (
  spaceId: string,
  campaign: ReadySegmentCampaign
): CampaignTotals => {
  const { filters, range } = useMemo(() => createCampaignBaseQuery(campaign), [
    campaign
  ]);
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: [
        'c',
        'p',
        'v',
        'commission_sum_net',
        'gmv_sum_net',
        'aov_net',
        'order_count_net'
      ],
      filters
    };
  }, [filters, range]);

  const mapFn = useCallback(
    (response) => getCampaignTotals(campaign, response),
    [campaign]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, query),
    mapFn,
    true
  );
};

const AVAILABLE_METRICS: CampaignMetric[] = ['commission_sum_net', 'c'];

export const timeseriesCampaignQuery = (
  campaign: ReadySegmentCampaign,
  interval: AnalyticsInterval,
  metric: CampaignMetric
): AnalyticsQuery => ({
  ...createCampaignBaseQuery(campaign),
  interval,
  select: [metric],
  orderBy: [{ field: 'interval', direction: 'DESC' }]
});

const useTimeseriesData = (
  campaign: ReadySegmentCampaign,
  interval: AnalyticsInterval,
  metric: CampaignMetric,
  mode: 'raw' | 'aggregated'
): LoadingValue<SummedSeries> => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(
    () => timeseriesCampaignQuery(campaign, interval, metric),
    [campaign, interval, metric]
  );

  const mapper = useCallback(
    (response) => aggregateRows(response.rows, mode, undefined, metric),
    [metric, mode]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    mapper,
    true
  );
};

export const SegmentCampaignReportToolbar = ({
  campaign,
  metric,
  setMetric
}: {
  campaign: ReadySegmentCampaign;
  metric: CampaignMetric;
  setMetric: (nextValue: CampaignMetric) => void;
}) => {
  const { tz } = useCurrentUser();
  const { ROUTES } = useRoutes();
  return (
    <PageToolbar sticky offset={DEFAULT_OFFSET}>
      <PageToolbarSection flex={2}>
        <div>
          <Typography
            variant="h6"
            component="span"
            style={{
              fontWeight: 'bold',
              position: 'relative',
              top: -3,
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
              gap: 16
            }}
          >
            {campaign.name}
          </Typography>
          <Link
            to={ROUTES.campaigns.segment.details.url(campaign.id, 'report')}
          >
            <FlexContainer
              spacing={0.5}
              alignItems="center"
              className={css((t) => ({ color: t.palette.grey[600] }))}
            >
              <Edit
                size={16}
                className={css(() => ({
                  position: 'relative',
                  top: -1
                }))}
              />
              <Typography variant="caption" style={{ whiteSpace: 'nowrap' }}>
                Edit campaign
              </Typography>
            </FlexContainer>
          </Link>
        </div>
      </PageToolbarSection>
      <PageToolbarSection flex={2} justifyContent="flex-end">
        <PauseButton campaign={campaign} />
        <FlexContainer spacing={1.5}>
          <FlexContainer alignItems="center">
            <CampaignStatusIcon status={campaign.status} />
            <Typography variant="body1">
              {campaignStatusTitle(campaign.status)}
            </Typography>
          </FlexContainer>
          <CampaignMetricSelector
            metric={metric}
            setMetric={setMetric}
            metrics={AVAILABLE_METRICS}
          />
          <CampaignTimeframe
            start={campaign.timeframe.start}
            end={campaign.timeframe.end}
            timezone={tz}
          />
        </FlexContainer>
      </PageToolbarSection>
    </PageToolbar>
  );
};

const SegmentCampaignTimeseries: React.FC<{
  campaign: ReadySegmentCampaign;
  metric: CampaignMetric;
  summary: React.ReactNode;
}> = ({ campaign, metric, summary }) => {
  const [mode, setMode] = useState<'raw' | 'aggregated'>('aggregated');
  const { interval, setInterval, options } = useIntervalSelectorState();
  const analyticsInterval = useAnalyticsInterval(interval);
  const goal = getCampaignGoalForMetric(campaign, metric);

  const series = useTimeseriesData(campaign, analyticsInterval, metric, mode);

  return (
    <CampaignTimeseries
      campaign={campaign}
      metric={metric}
      mode={mode}
      series={series}
      interval={interval}
      setInterval={setInterval}
      intervalOptions={options}
      seriesName={metricTitle(metric)}
      seriesColor={COLORS.blue.blue3}
      footer={
        <FlexContainer spacing={3}>
          <WithShape color={COLORS.blue.blue3} shape="square">
            {metricTitle(metric)}
          </WithShape>
          <Legend
            items={[
              {
                shape: 'circle',
                color: '#444',
                label: 'Cumulatively',
                active: mode === 'aggregated',
                onClick: () => setMode('aggregated')
              },
              {
                shape: 'circle',
                color: '#444',
                label: `Per ${interval.unit}`,
                active: mode === 'raw',
                onClick: () => setMode('raw')
              }
            ]}
          />
        </FlexContainer>
      }
      summary={summary}
      exportQuery={timeseriesCampaignQuery(campaign, analyticsInterval, metric)}
      goal={goal}
    />
  );
};

const Container = styled('div')((p) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: p.theme.spacing(4)
}));

const SegmentCampaignReportInner: React.FC<{
  campaign: ReadySegmentCampaign;
}> = ({ campaign }) => {
  const { space } = useCurrentUser();
  const totals = useSegmentCampaignTotals(space.id, campaign);
  const [metric, setMetric] = useCampaignMetricSelectorState(campaign);

  return (
    <>
      <SegmentCampaignReportToolbar
        campaign={campaign}
        metric={metric}
        setMetric={setMetric}
      />
      <Container>
        <PauseBanner campaign={campaign} />
        <CampaignTotalCardsContainer>
          <CampaignClicksCard campaign={campaign} totals={totals} />
          <CampaignCPCCard campaign={campaign} totals={totals} />
          <CampaignEstimatedCPCCard campaign={campaign} totals={totals} />
          <CampaignBudgetCard campaign={campaign} totals={totals} />
          <CampaignPageviewsCard campaign={campaign} totals={totals} />
          <CampaignImpressionsCard campaign={campaign} totals={totals} />
        </CampaignTotalCardsContainer>
        <SegmentCampaignTimeseries
          campaign={campaign}
          metric={metric}
          summary={
            <CampaignSummaryLayout totals={totals}>
              {(totalValues) => {
                const durationRatio =
                  moment().diff(campaign.timeframe.start, 's') /
                  moment(campaign.timeframe.end).diff(
                    campaign.timeframe.start,
                    's'
                  );

                return (
                  <>
                    <EarningsGoalProgress
                      totalValues={totalValues}
                      durationRatio={durationRatio}
                      campaign={campaign}
                    />
                    <TrafficGoalProgress
                      totalValues={totalValues}
                      field={'c'}
                      durationRatio={durationRatio}
                      campaign={campaign}
                    />
                  </>
                );
              }}
            </CampaignSummaryLayout>
          }
        />
        <SegmentCampaignTables campaign={campaign} metric={metric} />
      </Container>
    </>
  );
};

export const SegmentCampaignReport: React.FC<{ campaignId: string }> = ({
  campaignId
}) => {
  const campaign = useSegmentCampaign(campaignId);
  return (
    <>
      <Helmet>
        <title>Campaign report | Affilimate</title>
      </Helmet>
      <SegmentCampaignPage campaign={campaign} scope="campaigns.view">
        {({ campaign }) => {
          switch (campaign.status) {
            case 'completed':
            case 'running':
              return <SegmentCampaignReportInner campaign={campaign} />;
            default:
              return <NoPermissions />;
          }
        }}
      </SegmentCampaignPage>
    </>
  );
};
