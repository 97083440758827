import React from 'react';
import {
  CampaignTotalsObject,
  CampaignTotalWithGoal,
  hasGoal
} from '../service/totals';
import { formatCurrency, formatNumber } from '../../../components/Number';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../components/Charts/Util';
import pluralize from 'pluralize';
import { metricName } from '../../../services/analyticsV2/metrics';
import { ReadyManualCampaign } from '../service/manual-campaign';
import { ReadySegmentCampaign } from '../service/segment-campaign';
import { hasROASGoal } from '../../../domainTypes/campaigns';

const Target: React.FC<{
  field: CampaignTotalWithGoal;
  avg: number;
}> = ({ field, avg }) => {
  const currency = useSpaceCurrency();
  switch (field) {
    case 'estimatedCPC':
      return (
        <>
          <strong>
            {formatChartCurrency(avg, currency, {
              excludeCents: true
            })}
          </strong>{' '}
          in earnings
        </>
      );
    case 'gmv': {
      return (
        <>
          <strong>
            {formatChartCurrency(avg, currency, {
              excludeCents: true
            })}
          </strong>{' '}
          in GMV
        </>
      );
    }
    case 'c':
      return (
        <>
          <strong>{formatChartNumber(avg)}</strong> clicks
        </>
      );
    case 'p':
      return (
        <>
          <strong>{formatChartNumber(avg)}</strong> pageviews
        </>
      );
  }
};

const GoalForecastNotice: React.FC<{
  campaign: ReadyManualCampaign | ReadySegmentCampaign;
  totalValues: CampaignTotalsObject;
  field: CampaignTotalWithGoal;
  durationRatio: number;
}> = ({ totalValues, campaign, field, durationRatio }) => {
  const metric = totalValues[field];
  if (!hasGoal(metric)) {
    return null;
  }
  const goalRatio = metric.goal.percentage;

  const isBehind = goalRatio < durationRatio;
  if (!isBehind) {
    return (
      <Typography variant="body2" paragraph color="textSecondary">
        You're on track to meet your goal. Keep up the good work!
      </Typography>
    );
  }

  const targetDailyAvg =
    (metric.goal.value - metric.value) /
    moment(campaign.timeframe.end).diff(moment(), 'd');

  const currentDailyAvg =
    metric.value / moment().diff(campaign.timeframe.start, 'd');

  const percentage = formatNumber({
    n: (targetDailyAvg - currentDailyAvg) / currentDailyAvg,
    format: 'percent',
    digits: 0
  });

  return (
    <Typography variant="body2" paragraph color="textSecondary">
      This campaign is behind. You'll need to average{' '}
      <Target field={field} avg={targetDailyAvg} /> per day to reach your goal.
      This is <strong>{percentage}</strong> more per day than what you've done
      so far.
    </Typography>
  );
};

export const EarningsGoalProgress: React.FC<{
  campaign: ReadySegmentCampaign;
  totalValues: CampaignTotalsObject;
  durationRatio: number;
}> = ({ campaign, totalValues, durationRatio }) => {
  const currency = useSpaceCurrency();
  const earnings = totalValues.estimatedCPC;

  if (!hasGoal(earnings)) return null;

  return (
    <>
      <Typography variant="body2" paragraph color="textSecondary">
        You’ve reached{' '}
        <strong>
          {formatNumber({ n: earnings.goal.percentage, format: 'percent' })}
        </strong>{' '}
        of your <strong>{formatCurrency(earnings.goal.value, currency)}</strong>{' '}
        budget{' '}
        <strong>
          {formatNumber({ n: durationRatio, format: 'percent', digits: 0 })}
        </strong>{' '}
        of the way through the campaign timeframe.
      </Typography>
      <GoalForecastNotice
        campaign={campaign}
        totalValues={totalValues}
        durationRatio={durationRatio}
        field="estimatedCPC"
      />
    </>
  );
};

const GMVGoalProgress: React.FC<{
  campaign: ReadyManualCampaign;
  totalValues: CampaignTotalsObject;
  durationRatio: number;
}> = ({ campaign, totalValues, durationRatio }) => {
  const currency = useSpaceCurrency();
  const gmv = totalValues.gmv;
  if (!hasGoal(gmv)) {
    return null;
  }

  const isRoasGoal = hasROASGoal(campaign);
  const goalStr = isRoasGoal ? (
    (() => {
      // This is temporary because the totals values are not correct
      // in this usage anymore
      const roasGoal = campaign.goals.filter((g) => g.type === 'roas')[0];

      if (!roasGoal) {
        return null;
      }

      return <strong>{roasGoal.amount}x ROAS</strong>;
    })()
  ) : (
    <strong>{formatCurrency(gmv.goal.value, currency)}</strong>
  );

  return (
    <>
      <Typography variant="body2" paragraph color="textSecondary">
        You’ve reached{' '}
        <strong>
          {formatNumber({ n: gmv.goal.percentage, format: 'percent' })}
        </strong>{' '}
        of your {goalStr} goal{' '}
        <strong>
          {formatNumber({ n: durationRatio, format: 'percent', digits: 0 })}
        </strong>{' '}
        of the way through the campaign timeframe.
      </Typography>
      <GoalForecastNotice
        campaign={campaign}
        totalValues={totalValues}
        durationRatio={durationRatio}
        field="gmv"
      />
    </>
  );
};

// NOTE: can we reuse them for segment campaigns?!
export const TrafficGoalProgress: React.FC<{
  totalValues: CampaignTotalsObject;
  field: 'p' | 'c';
  durationRatio: number;
  campaign: ReadyManualCampaign | ReadySegmentCampaign;
}> = ({ totalValues, field, durationRatio, campaign }) => {
  const metric = totalValues[field];
  if (!hasGoal(metric)) {
    return null;
  }

  return (
    <>
      <Typography variant="body2" paragraph color="textSecondary">
        You’ve reached{' '}
        <strong>
          {formatNumber({
            n: metric.goal.percentage,
            format: 'percent',
            digits: 0
          })}
        </strong>{' '}
        of your <strong>{formatChartNumber(metric.goal.value)}</strong>{' '}
        {pluralize(metricName(field), 2)} goal{' '}
        <strong>
          {formatNumber({ n: durationRatio, format: 'percent', digits: 0 })}
        </strong>{' '}
        of the way through the campaign timeframe.
      </Typography>
      <GoalForecastNotice
        campaign={campaign}
        totalValues={totalValues}
        durationRatio={durationRatio}
        field={field}
      />
    </>
  );
};

export const RunningCampaignProgress: React.FC<{
  campaign: ReadyManualCampaign;
  totalValues: CampaignTotalsObject;
}> = ({ campaign, totalValues }) => {
  const durationRatio =
    moment().diff(campaign.timeframe.start, 's') /
    moment(campaign.timeframe.end).diff(campaign.timeframe.start, 's');

  return (
    <>
      <GMVGoalProgress
        campaign={campaign}
        totalValues={totalValues}
        durationRatio={durationRatio}
      />
      <TrafficGoalProgress
        campaign={campaign}
        totalValues={totalValues}
        field="p"
        durationRatio={durationRatio}
      />
      <TrafficGoalProgress
        campaign={campaign}
        totalValues={totalValues}
        field="c"
        durationRatio={durationRatio}
      />
    </>
  );
};
