import React, { useMemo } from 'react';
import { TimeseriesChart } from '../../../../components/analytics_v2/Chart/TimeseriesChart';
import { xAxis } from '../../../../components/analytics_v2/Chart/xAxis';
import { yAxis } from '../../../../components/analytics_v2/Chart/yAxis';
import { barSeries } from '../../../../components/analytics_v2/Chart/barSeries';
import { Paper } from '@material-ui/core';
import {
  AnalyticsField,
  AnalyticsQuery
} from '../../../../domainTypes/analytics_v2';
import { LoadingValue } from '../../../../services/db';
import { css } from '../../../../emotion';
import Typography from '@material-ui/core/Typography';
import { Loader } from '../../../../components/Loader';
import { Centered } from '../../../../layout/Centered';
import { AxisDomain, ReferenceLine } from 'recharts';
import { timeseriesReferences } from './TimeseriesReferences';
import { FlexContainer } from '../../../../layout/Flex';
import { SummedSeries } from '../../service/aggregate';
import { isNil } from 'lodash';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { ChartCardFooter } from '../../../../components/Charts/ChartCard';
import { tooltip } from './CampaignTooltip';
import {
  IntervalSelector,
  IntervalSelectorOption
} from '../../../../components/IntervalSelector';
import { useAnalyticsInterval } from '../../../../hooks/timeframe';
import { ManualCampaign } from '../../service/manual-campaign';
import { isCampaignRunning } from '../../service/lifecycle';
import { TimeframeIntervalDefinition } from '../../../../domainTypes/timeframe';
import { SegmentCampaign } from '../../service/segment-campaign';
import { CampaignMetric } from '../../../../domainTypes/campaigns';

export type ChartMode = 'raw' | 'aggregated';

export const CampaignTimeseriesContainer: React.FC = ({ children }) => {
  return (
    <Paper>
      <div
        className={css((t) => ({
          display: 'grid',
          position: 'relative',
          gridTemplateColumns: '1fr 300px',
          paddingTop: t.spacing(4),
          paddingLeft: t.spacing(4),
          paddingBottom: t.spacing(8),
          paddingRight: t.spacing(4),
          columnGap: t.spacing(2)
        }))}
      >
        {children}
      </div>
    </Paper>
  );
};

const HEIGHT = 250;

export const CampaignTimeseries: React.FC<{
  campaign: ManualCampaign | SegmentCampaign;
  metric: CampaignMetric;
  mode: ChartMode;
  series: LoadingValue<SummedSeries>;
  interval: TimeframeIntervalDefinition;
  setInterval: (value: TimeframeIntervalDefinition) => void;
  intervalOptions?: Array<IntervalSelectorOption>;
  field?: AnalyticsField;
  seriesName: string | ((fieldValue: string) => string);
  seriesColor: string | ((fieldValue: string) => string);
  footer: React.ReactNode;
  summary: React.ReactNode;
  exportQuery: AnalyticsQuery;
  goal: number | null;
}> = ({
  campaign,
  metric,
  mode,
  goal,
  series,
  interval,
  setInterval,
  intervalOptions,
  seriesName,
  seriesColor,
  summary,
  footer,
  exportQuery,
  field
}) => {
  const [data, loading] = series;
  const analyticsInterval = useAnalyticsInterval(interval);

  const domain = useMemo<Readonly<[AxisDomain, AxisDomain]>>(() => {
    if (mode === 'raw') return [0, 'dataMax'];
    return [0, (max: number) => Math.ceil(Math.max(max, goal ?? 0) / 10) * 10];
  }, [goal, mode]);

  return (
    <CampaignTimeseriesContainer>
      {!data || loading ? (
        <Centered height={HEIGHT}>
          <Loader size={32} />
        </Centered>
      ) : (
        <TimeseriesChart
          data={data.series}
          metrics={[metric]}
          field={field}
          ResponsiveContainerProps={{ height: HEIGHT }}
          ComposedChartProps={{
            margin: { top: 25, right: 5, left: 20, bottom: 5 }
          }}
        >
          {({ context, fieldValues }) => {
            return [
              xAxis(context, interval.unit, {
                textAnchor: 'end',
                tickLine: false,
                minTickGap: 120,
                tick: {
                  transform: 'translate(0, 12)'
                }
              }),
              yAxis(metric, 'right', context, {
                domain
              }),
              <ReferenceLine
                y={0}
                yAxisId={metric}
                xAxisId="timestamp"
                stroke="#bbb"
              />,
              ...timeseriesReferences(mode, metric, data.sum, goal, {
                start: data.intervalAfterNow,
                end: data.lastInterval
              }),
              barSeries(fieldValues, metric, {
                name: seriesName,
                fill: seriesColor,
                maxBarSize: 48
              }),
              tooltip(metric, analyticsInterval, context, goal, !isNil(field))
            ];
          }}
        </TimeseriesChart>
      )}
      <FlexContainer direction="column" alignItems="flex-start">
        <FlexContainer
          alignItems="center"
          className={css(() => ({
            width: '100%',
            marginBottom: 12
          }))}
        >
          <Typography
            variant="body1"
            style={{ fontWeight: 'bold', flexGrow: 1 }}
          >
            Campaign {isCampaignRunning(campaign) ? 'progress' : 'summary'}
          </Typography>
          <IntervalSelector
            value={interval}
            onChange={setInterval}
            options={intervalOptions}
          />
          <ExportQueryButton
            title={`Download performance per ${interval.unit}`}
            reportType="campaigns"
            query={exportQuery}
          />
        </FlexContainer>
        {summary}
        <ChartCardFooter>{footer}</ChartCardFooter>
      </FlexContainer>
    </CampaignTimeseriesContainer>
  );
};
